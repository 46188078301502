import * as React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Img from '../components/img';
import Pagination from '../components/pagination';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

const BlogList = ({ pageContext, data, location }) => {
  const { current, totalPageNumber } = pageContext;
  const { totalCount, nodes } = data.allMarkdownRemark;
  const title = '記事一覧';

  return (
    <Layout
      location={location}
      title={title}
    >
      <Seo
        title={title}
        location={location}
        type="blog-list"
        discription="ブログ一覧記事です。"
      />
      <BlogListLayoutContainer>
        <BlogListBlockContainer>
          <BlogListFlex>
            <BlogListHeader>
              <h2>記事一覧</h2>
              <p>現在 {totalCount} 記事あります</p>
            </BlogListHeader>
            <BlogListInner>
              {nodes.map((post, index) => {
                const title = post.frontmatter.title || post.fields.slug;
                const tags = post.frontmatter.tags.sort();
                return (
                  <BlogCardContents key={index}>
                    <Link
                      to={post.fields.slug}
                      itemProp="url"
                      className="p-0"
                    >
                      <figure>
                        <Img
                          alt={title}
                          image={post.frontmatter.hero}
                          className="img-fluid"
                        />
                      </figure>
                      <h2>{title}</h2>
                      <BlogDetailContainer>
                        <DetailTimeArea>
                          <FontAwesomeIcon icon={faClock} />
                          <small>{post.frontmatter.date}</small>
                        </DetailTimeArea>
                      </BlogDetailContainer>
                    </Link>
                    <DetailCategoryArea>
                      {tags.map((tag, index) => {
                        return (
                          <Link
                            key={`tag${index}`}
                            to={`/tags/${tag}/`}
                          >
                            {tag}
                          </Link>
                        );
                      })}
                    </DetailCategoryArea>
                  </BlogCardContents>
                );
              })}
            </BlogListInner>
            {/* ページネーション */}
          </BlogListFlex>
          <Pagination
            totalPageNumber={totalPageNumber}
            current={current}
            type="blogs"
          />
        </BlogListBlockContainer>
      </BlogListLayoutContainer>
    </Layout>
  );
};

export default BlogList;

export const pageQuery = graphql`
  query ($limit: Int!, $skip: Int!, $draft: [Boolean]!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      # $limit、$skip追加
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          pagetype: { eq: "blog" }
          draft: { in: $draft } # 下書きのページは表示させない
        }
      }
    ) {
      totalCount
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          description
          hero
          tags
        }
      }
    }
  }
`;

const BlogListLayoutContainer = styled.div`
  padding-top: 32px;

  @media (min-width: 768px) {
    padding-top: 48px;
  }

  @media (max-width: 768px) {
    padding-bottom: 24px;
  }

  @media (min-width: 769px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
`;

const BlogListBlockContainer = styled.div`
  display: block;
  padding-top: 48px;
  padding-bottom: 48px;
  @media (min-width: 768px) {
    width: 75%;
    margin: 0 auto;
    margin-top: 0px;
  }
`;

const BlogListFlex = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    width: 100%;
    flex: 0 0 auto;
  }
`;

const BlogListInner = styled.div`
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    width: 100%;
    flex: 0 0 auto;
  }
`;

const BlogListHeader = styled.div`
  width: 100%;

  h2 {
    text-align: center;
    margin-bottom: 2.3rem;

    & time {
      text-align: left;
    }

    &::after {
      margin: 8px auto;
      content: '';
      display: block;
      width: 60px;
      height: 3px;
      background-color: #0a0b37;
    }
  }

  p {
    text-align: center;
  }
`;

const BlogDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: 768px) and (max-width: 1200px) {
    display: block;
  }
`;

const DetailTimeArea = styled.div`
  & time {
    display: inline-block;
    margin-left: 0.2rem;
    font-size: 0.9rem;
  }
`;

const DetailCategoryArea = styled.div`
  display: flex;
  & p {
    margin-left: 1rem;
    &:first-child {
      margin-left: 0px;
    }
  }

  & > a {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 8px;
    background-color: #0a0b37;
    color: #ffffff;
    font-size: 12px;
    margin-right: 8px;
    transition: all 0.3s;

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      color: #ffffff;
      opacity: 0.6;
    }
  }
`;

const BlogCardContents = styled.article`
  padding: 0.3rem;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    width: 33.33333%;
    flex: 0 0 auto;
    padding: 0.7rem;
  }

  & > a {
    display: block;
    color: #5a5a5a;
    padding: 0.5rem;
    transition: all 0.3s;
    &:hover {
      color: #5a5a5a;
      opacity: 0.7;
    }
    &:hover .gatsby-image-wrapper {
      transform: scale(1.05);
    }

    &:hover figure::after {
      font-size: 90%;
      letter-spacing: 2px;
      opacity: 0.7;
    }
  }

  & figure {
    position: relative;
    // ホバー時に拡大した画像ではみ出した部分を切り取る
    overflow: hidden;

    & .gatsby-image-wrapper {
      transition: all 0.3s;
    }

    &::after {
      content: 'READ MORE';
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 50%;
      color: #fff;
      opacity: 0;
      letter-spacing: 1.5px;
      transition: all 0.3s;
    }
  }

  & h2 {
    font-size: 1.3rem;
    margin-bottom: 1.3rem;
  }
`;
